<template>
  <div class="login forgot-password bg-grey-lightest">
    <div class="container">
      <div class="login_form bg-white">
        <form @submit.prevent="" class="flex flex-col gap-30">
          <div class="top_line text-2xl">
            <div class="logo">
              <img v-if="logo_url" :src="logo_url" alt="logo">
              <img v-else src="@/assets/images/logo.png" alt="logo">
            </div>
            <div class="title">
              Forgot Password?
            </div>
          </div>
          <span class="text-center mb-2">Enter the username or email address you use for your account and we’ll send instructions to reset your password to the registered email address.</span>
          <div class="body flex gap-15 flex-col">
            <div class="input_group">
              <input
                  v-model="username"
                  type="text"
                  id="username"
                  placeholder="Username or email"
              />
              <div  v-if="usernameErrors.length > 0">
                <p class="error">
                  {{ usernameErrors[0] }}
                </p>
              </div>
            </div>
          </div>
          <div class="edit_profile_actions flex-row flex justify-between items-center gap-15">
            <button class="btn w-1/2 green-white"
                    @click="processResetPass"
            >
              Send Instructions
            </button>
            <router-link :to="{name: 'Login'}" class="btn w-1/2 forgot_pass bold-green text-right">
              Cancel
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {infoMessage} from "../../services/messages";

export default {
  name: "ForgotPassword",
  data() {
    return {
      username: null,
      usernameErrors: [],
      logo_url: `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).logo_url || null,
    }
  },
  methods: {
    errorsHandler(errors) {
      this.usernameErrors = errors;
    },
    async processResetPass(){
      this.usernameErrors = [];
      const http = this.$http;
      const {username} = this;
      const request_data = {username};
      let errors = null;
      const response = await http.post(`${http.apiUrl()}/auth/forgot-password`, request_data).catch(err => {
        errors = err.response?.data?.message;
        if (typeof errors === 'string') {
          return infoMessage(errors || 'Oops. Something went wrong...', 'error')
        }
        return this.errorsHandler(errors);
      });
      if (!response) {
        return
      }
      await infoMessage('Message sent to your email', 'success')
      return await this.$router.push({name: "Login"})
    },
  }
}
</script>

<style scoped>

</style>
