import Toastify from 'toastify-js'

function infoMessage(text, cssClass = 'info') {
    Toastify({
        backgroundColor: '#ffffff',
        className: cssClass,
        text: text,
        duration: 3500,
        close: true,
        stopOnFocus: true,
        onClick: function () {
        } // Callback after click
    }).showToast();
}

function errorMessage(text = 'Oops. Something went wrong... Try again later.') {
    Toastify({
        text: text,
        duration: 3000,
        newWindow: true,
        close: true,
        width: 400,
        gravity: "top", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        backgroundColor: "rgba(255,0,60,0.73)",
        stopOnFocus: true, // Prevents dismissing of toast on hover
        onClick: function () {
        } // Callback after click
    }).showToast();
}

export {infoMessage, errorMessage};
